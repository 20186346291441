import { useState, createContext, ReactNode } from "react";


type BuscaContextData = {
    handleSetSearch: (expressao: string) => Promise<void>
    handleProcesso: (processo: string) => Promise<void>
    search: string;
    processo: string;
}

type BuscaProviderProps = {
    //O tipo ReactNode pode receber qualquer coisa, componentes, funções etc
    children: ReactNode
}

export const BuscaContext = createContext({} as BuscaContextData);


export function BuscaProvider({ children }: BuscaProviderProps) {
    const [search, setSearch] = useState<string>('');
    const [processo, setProcesso] = useState<string>('');

    async function handleSetSearch(expressao: string) {
        setSearch(expressao);
    }

    async function handleProcesso(processo: string) {
        setProcesso(processo);
    }

    return (
        <BuscaContext.Provider value={{ handleSetSearch, handleProcesso, search, processo }}>
            {children}
        </BuscaContext.Provider>
    )
}
