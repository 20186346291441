import { createContext, ReactNode, useContext, useState } from 'react';
import { parseCookies } from "nookies";
import { api } from '../../services/apiClient';
import { TribunalContext } from '../tribunal';

interface TabsProviderProps {
    children: ReactNode;
}

type TabsContextData = {
    handleTabs: (index: number) => Promise<void>;
    getOrigemDados: (params: string) => Promise<void>;
    origemDadosDescricoes: any;
    tabIndex: number;
}

interface DescricaoProps {
    label: any;
    value: any;
}


export const TabsContext = createContext({} as TabsContextData);

export function TabsProvider({ children }: TabsProviderProps) {
    const [tabIndex, setTabIndex] = useState(1);
    const [origemDadosDescricoes, setOrigemDadosDescricoes] = useState<DescricaoProps[]>([]);
    const tribunaisParams = new URLSearchParams();
    const { setTribunais, setTribunaisSelected } = useContext(TribunalContext);

    async function handleTabs(index: number) {
        setTabIndex(index);
    }

    async function getOrigemDados(params: string) {

        try {

            tribunaisParams.append('tipo', params);

            const { 'jurisconta.token': token } = parseCookies();
            const uri = `api/v1/origem_dados/`

            if (token) {
                api.get(uri, {
                    params: { 'tipo': tribunaisParams.get('tipo') },
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {
                    const desc_duplicada = response.data.map(origemDados => origemDados.descricao);
                    const desc_unica = Array.from(new Set(desc_duplicada));
                    setOrigemDadosDescricoes([]);
                    setTribunaisSelected([])
                    desc_unica.forEach(item => {
                        setOrigemDadosDescricoes((orimgeDado) => {
                            orimgeDado.push({ label: item, value: item })
                            return [...orimgeDado]
                        });
                    });
                    setTribunais(response.data);

                }).catch(error => {
                    console.log(error)
                })
            }

        } catch (err) {
            console.log(err)
        }

    }



    // async function handleSelectedTribunal(tribunal: Tribunal) {

    //     const values = [...tribunaisSelected, tribunal]
    //     values.filter((item) => setTribunaisSelected(item));

    // }

    return (
        <TabsContext.Provider value={{ tabIndex, origemDadosDescricoes, handleTabs, getOrigemDados }}>
            {children}
        </TabsContext.Provider>
    )
}

