import { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { SidebarDrawerProvider } from '../contexts/SidebarDrawerContext';
import { AuthProvider } from '../contexts/auth/authContext';
import { DocumentoProvider } from '../contexts/documento/documentoContext';
import { TabsProvider } from '../contexts/tabs/tabsContext';
import { BuscaProvider } from '../contexts/busca/buscaContext';
import { PrejulgadosProvider } from '../contexts/prejulgados/prejulgadosContext';
import { AcordaosProvider } from '../contexts/acordaos/acordaosContext';
import { TribunalProvider } from '../contexts/tribunal';
import { JurisprudenciaSelecionadaProvider } from '../contexts/jurisSelecionada/jurisSelecionadaContext';
import { AnoProvider } from '../contexts/filtroAno';
import { InformativoProvider } from '../contexts/informativo/informativoContext';

function MyApp({ Component, pageProps }: AppProps) {

  return (
    <AuthProvider>
      <TribunalProvider>
        <AnoProvider>
          <TabsProvider>
            <ChakraProvider>
              <SidebarDrawerProvider>
                <BuscaProvider>
                  <PrejulgadosProvider>
                    <InformativoProvider>
                      <AcordaosProvider>
                        <JurisprudenciaSelecionadaProvider>
                          <DocumentoProvider>
                            <Component
                              {...pageProps} />
                          </DocumentoProvider>
                        </JurisprudenciaSelecionadaProvider>
                      </AcordaosProvider>
                    </InformativoProvider>
                  </PrejulgadosProvider>
                </BuscaProvider>
              </SidebarDrawerProvider>
            </ChakraProvider>
          </TabsProvider>
        </AnoProvider>
      </TribunalProvider>
    </AuthProvider>
  )
}

export default MyApp
