import { useState, createContext, ReactNode } from "react";


interface Tribunal {
    id: number;
    descricao: string;
    tipo: string;
}

type TribunalContextData = {
    tribunais: Tribunal[];
    tribunaisSelected: Tribunal[];
    handleSelectedTribunal: (tribunal: any) => Promise<void>;
    setTribunais: (tribunais) => void;
    setTribunaisSelected: (Tribunal) => void;

}

type TribunalProviderProps = {

    //O tipo ReactNode pode receber qualquer coisa, componentes, funções etc
    children: ReactNode
}

export const TribunalContext = createContext({} as TribunalContextData);


export function TribunalProvider({ children }: TribunalProviderProps) {
    const [tribunais, setTribunais] = useState<Tribunal[]>([]);
    const [tribunaisSelected, setTribunaisSelected] = useState<Tribunal[]>([]);

    async function handleSelectedTribunal(selecedItems: any) {
        if (selecedItems.length > 0) {
            let newState: Tribunal[] = [];
            tribunais.forEach(tribunal => {
                if (tribunal.tipo === 'ACORDAO') {
                    selecedItems.forEach(item => {
                        if (item.label === tribunal.descricao) {
                            const tribunaisResul = tribunais.filter((result) => result.descricao === item.label);
                            console.log(tribunaisResul);
                            tribunaisResul.forEach(valor => newState.push(valor));
                        }
                    });
                }

                if (tribunal.tipo === 'JURISSELECIONADA') {
                    selecedItems.forEach(item => {
                        if (item.label === tribunal.descricao) {
                            const tribunaisResul = tribunais.filter((result) => result.descricao === item.label);
                            console.log(tribunaisResul);
                            tribunaisResul.forEach(valor => newState.push(valor));
                        }
                    });
                }

                if (tribunal.tipo === 'PREJULGADO' || tribunal.tipo === 'SUMULA') {
                    selecedItems.forEach(item => {
                        if (item.label === tribunal.descricao) {
                            const tribunaisResul = tribunais.filter((result) => result.descricao === item.label);
                            console.log(tribunaisResul);
                            tribunaisResul.forEach(valor => newState.push(valor));
                        }
                    });
                }

                if (tribunal.tipo === 'BOLETIMPESSOAL' || tribunal.tipo === 'CONSULTARESPOSTA' || tribunal.tipo === 'BOLETIMJURISPRUDENCIA' || tribunal.tipo === 'INFORMATIVOLICITACOESCONTRATOS') {
                    selecedItems.forEach(item => {
                        if (item.label === tribunal.descricao) {
                            const tribunaisResul = tribunais.filter((result) => result.descricao === item.label);
                            console.log(tribunaisResul);
                            tribunaisResul.forEach(valor => newState.push(valor));
                        }
                    });
                }

            })
            setTribunaisSelected(newState)
        } else {
            setTribunaisSelected([])
        }
    }

    return (
        <TribunalContext.Provider value={{ tribunais, tribunaisSelected, handleSelectedTribunal, setTribunais, setTribunaisSelected }}>
            {children}
        </TribunalContext.Provider>
    )
}
