import { useState, createContext, ReactNode } from "react";
import { parseCookies } from "nookies";
import { setTimeout } from "timers";
import { api } from "../../services/apiClient";

interface Informacoes {
    parecer: string;
    processo: string;
    tipo: string;
}

interface OrigemDados {
    id: number;
    sigla: string;
    descricao: string;
    fonte_dados: string;
    tipo: string
}


interface Prejulgado {
    id: number;
    informacoes: Informacoes;
    texto: string;
    origem_dados: OrigemDados
}


type PrejulgadosContextData = {
    handleGetPrejulgados: (expressao: String, filtros?: []) => Promise<void>;
    handleGetPrejulgadoSelected: (id: Number) => Promise<void>;
    handleGoToPage: (page: string) => void;
    handleCurrentPage: (page: number) => void;
    prejulgados: Prejulgado[];
    isLoading: boolean;
    prejulgadoSelected: Prejulgado;
    count: number;
    next: string;
    previous: string;
    numberPage: number;
    currentPage: number;
}

type PrejulgadosProviderProps = {

    //O tipo ReactNode pode receber qualquer coisa, componentes, funções etc
    children: ReactNode
}

export const PrejulgadosContext = createContext({} as PrejulgadosContextData);


export function PrejulgadosProvider({ children }: PrejulgadosProviderProps) {
    const [prejulgados, setPrejulgados] = useState<Prejulgado[]>([]);
    const [prejulgadosSeleced, setPrejulgadosSeleced] = useState<Prejulgado[]>([]);
    const [count, setCount] = useState<number>();
    const [numberPage, setNumberPage] = useState<number>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [next, setNext] = useState<string>();
    const [previous, setPrevious] = useState<string>();
    const [prejulgadoSelected, setPrejulgadoSelected] = useState<Prejulgado>();
    const [isLoading, setIsLoading] = useState(false)


    async function handleGetPrejulgados(expressao: String, filtros) {
        const searchParams = new URLSearchParams();
        let tribunais: [] = [];
        let filterTribunais: any

        if (expressao.length > 0) {
            searchParams.append('search', expressao.toString());
        }


        if (filtros.tribunais) {

            filtros.tribunais.map(tribunal => {
                tribunais.push(tribunal.id)
            }
            )
            tribunais.forEach((id, index) => {
                if (index === 0) {
                    filterTribunais = id
                } else {
                    filterTribunais = filterTribunais + '__' + id
                }
            })
            if (filterTribunais !== undefined) {

                searchParams.append('origem_dados__in', filterTribunais)
            }
        }
        if ((filtros.ano !== 'Invalid date' && filtros.ano !== 'Invalid date') && (filtros.ano['de'] !== undefined && filtros.ano['ate'] !== undefined)) {
            searchParams.append('ano__range', JSON.stringify(filtros.ano['de']) + '__' + JSON.stringify(filtros.ano['ate']))
        }


        setIsLoading(true)
        try {

            const { 'jurisconta.token': token } = parseCookies();
            const uri = `api/v1/prejulgados-es/`

            if (token) {
                api.get(uri, {
                    params: searchParams,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {
                    let numPage = (response.data.count / 3)
                    setNumberPage(Math.ceil(numPage))
                    setCount(response.data.count);
                    setNext(response.data.next);
                    setPrevious(response.data.previous);
                    setPrejulgados(response.data.results)
                })
                    .then(() => {
                        setIsLoading(false)
                        // setTimeout(() => {
                        // }, 2000)

                    })
                    .catch(error => {
                        console.log(error)
                        setIsLoading(false)
                    })
            }

        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }

    }

    async function handleGetPrejulgadoSelected(id: Number) {
        setIsLoading(true)
        try {

            const { 'jurisconta.token': token } = parseCookies();
            const uri = `api/v1/prejulgado/${id}/`

            if (token) {
                api.get(uri, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {
                    setPrejulgadoSelected(response.data)
                })
                    .then(() => {
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 2000)

                    })
                    .catch(error => {
                        console.log(error)
                        setIsLoading(false)
                    })
            }

        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }

    }

    function getInNextAno(value) {
        const regex = new RegExp('ano=(.*)page=');
        const result = regex.exec(value);
        if (result != null) {
            return result[1]
        }
        return
    };

    function getInNextSeach(value: string) {
        const regex = new RegExp('search=(.*)&tribunais');
        const result = regex.exec(value);
        return result[1];
    };

    function handleCurrentPage(page: number) {
        setCurrentPage(page)
    }

    function getInNextTribunais(value) {
        const regex = new RegExp('tribunais=(.*)');
        const result = regex.exec(value);
        return result[1];
    };

    async function handleGoToPage(page: string) {
        let paramAno: string;
        let paramSeach: string;
        let paramTribunais: string;
        if (next !== null && previous === null) {
            paramAno = getInNextAno(next)
            paramSeach = getInNextSeach(next)
            paramTribunais = getInNextTribunais(next)
        }
        if (next !== null && previous !== null) {

            if (currentPage < Number(page)) {
                paramAno = getInNextAno(next)
                paramSeach = getInNextSeach(next)
                paramTribunais = getInNextTribunais(next)

            }
            if (currentPage > Number(page)) {
                paramAno = getInNextAno(previous)
                paramSeach = getInNextSeach(previous)
                paramTribunais = getInNextTribunais(previous)

            }
        }
        if (next == null && previous !== null) {
            paramAno = getInNextAno(previous)
            paramSeach = getInNextSeach(previous)
            paramTribunais = getInNextTribunais(previous)

        }
        try {
            setIsLoading(true)

            const { 'jurisconta.token': token } = parseCookies();
            const uri = `api/v1/prejulgados/?ano=${paramAno}page=${page}&search=${paramSeach}&tribunais=${paramTribunais}`

            if (token) {
                api.get(uri, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {
                    let numPage = (response.data.count / 3)
                    setNumberPage(Math.ceil(numPage))
                    setCount(response.data.count);
                    setNext(response.data.next);
                    setPrevious(response.data.previous);
                    setPrejulgadosSeleced(response.data.results)
                }).then(() => {
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 2000)

                })
                    .catch(error => {
                        console.log(error)
                        setIsLoading(false)
                    })
            }

        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }

    }


    return (
        <PrejulgadosContext.Provider value={{
            handleGetPrejulgadoSelected,
            handleGetPrejulgados,
            handleCurrentPage,
            handleGoToPage,
            prejulgadoSelected,
            currentPage,
            prejulgados,
            numberPage,
            isLoading,
            previous,
            count,
            next
        }}>
            {children}
        </PrejulgadosContext.Provider>
    )
}
