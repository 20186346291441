import { useState, useEffect, createContext, ReactNode } from "react";
import Router from "next/router";
import { setCookie, parseCookies, destroyCookie } from "nookies";
import decode from "jwt-decode";
import { api } from "../../services/apiClient";


type Usuario = {
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    get_all_permissions: [];
    // roles: [];
}

type AutenticarCredenciais = {
    username: string;
    password: string;
}

type AuthContextData = {
    autenticar: (credenciais: AutenticarCredenciais) => Promise<void>
    desconectar: () => void;
    usuario: Usuario | undefined;
    autenticado: boolean;
    isLoading: boolean;
    message: string;
    clearMessage: () => void;
}

type AuthProviderProps = {

    //O tipo ReactNode pode receber qualquer coisa, componentes, funções etc
    children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData);

let authChannel: BroadcastChannel;



export function AuthProvider({ children }: AuthProviderProps) {
    const [usuario, setUsuario] = useState<Usuario>();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const autenticado = !!usuario;

    function getUserIdByToken(token) {
        try {
            if (token) {
                const usuario = decode<{ user_id: Number }>(token)
                return usuario.user_id
            } else {
                Router.push('/')
            }
        } catch (error) {
            console.log('Token no formado inválido', error)
            return
        }

    }

    function desconectar() {
        destroyCookie(undefined, 'jurisconta.token');
        destroyCookie(undefined, 'jurisconta.refreshToken');
        setUsuario(null)

        authChannel.postMessage('sair')

        Router.push('/');
    }

    useEffect(() => {
        authChannel = new BroadcastChannel('sair');

        authChannel.onmessage = (message) => {
            switch (message.data) {
                case 'sair':
                    destroyCookie(undefined, 'jurisconta.token');
                    destroyCookie(undefined, 'jurisconta.refreshToken');
                    Router.push('/');

                    break;
                default:
                    break;
            }
        }

    }, []);

    useEffect(() => {
        const { 'jurisconta.token': token } = parseCookies();
        const uri = `api/v1/me/${getUserIdByToken(token)}/`

        if (token) {
            api.get(uri)
                .then(response => {
                    const { username, first_name, last_name, email, get_all_permissions } = response.data;
                    setUsuario({ username, first_name, last_name, email, get_all_permissions })
                    setMessage('')
                    setIsLoading(false)
                })
                .catch(() => {
                    desconectar();
                })
        }
    }, []);

    async function autenticar({ username, password }: AutenticarCredenciais) {
        setIsLoading(true)
        try {
            const response = await api.post('api/token/', {
                username,
                password
            });
            const { access, refresh } = response.data;

            setCookie(undefined, 'jurisconta.token', access, {
                maxAge: 60 * 60 * 24 * 30, // 30 Dias
                path: '/'
            });
            setCookie(undefined, 'jurisconta.refreshToken', refresh, {
                maxAge: 60 * 60 * 24 * 30, // 30 Dias
                path: '/'
            });

            api.defaults.headers['Authorization'] = `Bearer ${access}`;

            Router.push('/home');

            const { 'jurisconta.token': token } = parseCookies();
            const uri = `api/v1/me/${getUserIdByToken(token)}/`

            if (token) {
                api.get(uri, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {
                    const { username, first_name, last_name, email, get_all_permissions } = response.data;

                    setUsuario({ username, first_name, last_name, email, get_all_permissions })
                    setIsLoading(false)

                })
                    .catch(error => {
                        desconectar();
                    })
            }


        } catch (error) {
            if (error == 'Error: Request failed with status code 401') {
                setMessage('Usuario ou senha invalido')
                setIsLoading(false);
            } else {
                setIsLoading(false);
                console.log('Erro', error)
            }

        }

    }

    function clearMessage() {
        setMessage('');
    }

    return (
        <AuthContext.Provider value={{ autenticar, desconectar, autenticado, isLoading, usuario, message, clearMessage }}>
            {children}
        </AuthContext.Provider>
    )
}