import { useState, createContext, ReactNode, useEffect } from "react";


type AnoContextData = {
    handleChangeStartYear: (Ano: Date) => void;
    handleChangeEndYear: (Ano: Date) => void;
    handleDateStartValidate: (value: boolean) => void;
    handleDateEndValidate: (value: boolean) => void;
    startDate: Date;
    endDate: Date;
    dateStartInvalid: boolean;
    dateEndInvalid: boolean;

}

type AnoProviderProps = {

    //O tipo ReactNode pode receber qualquer coisa, componentes, funções etc
    children: ReactNode
}

export const AnoContext = createContext({} as AnoContextData);


export function AnoProvider({ children }: AnoProviderProps) {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [dateStartInvalid, setDateStartInvalid] = useState(false);
    const [dateEndInvalid, setDateEndInvalid] = useState(false);

    function handleChangeStartYear(ano: Date) {
        setStartDate(ano)
        setDateStartInvalid(false)
    }

    function handleChangeEndYear(ano: Date) {
        setEndDate(ano)
        setDateEndInvalid(false)
    }

    function handleDateStartValidate(value: boolean) {
        setDateStartInvalid(value)
    }

    function handleDateEndValidate(value: boolean) {
        setDateEndInvalid(value)
    }

    return (
        <AnoContext.Provider value={{ dateStartInvalid, dateEndInvalid, startDate, endDate, handleDateStartValidate, handleDateEndValidate, handleChangeStartYear, handleChangeEndYear }}>
            {children}
        </AnoContext.Provider>
    )
}