import { useState, createContext, ReactNode } from "react";
import { parseCookies } from "nookies";
import { setTimeout } from "timers";
import { api } from "../../services/apiClient";

interface Texto {
    enunciado: String;
    excerto: String;
}

interface EnunciadosRelacionados {
    chave: String;
    enunciado: String;
}

interface Informacoes {
    colegiado: String;
    codigo_acordao: String;
    codigo_sumula: String;
    autor_tese: String;
    data: String;
    funcao_autor: String;
    enunciados_relacionados: EnunciadosRelacionados;
}

interface OrigemDados {
    id: number;
    sigla: String;
    descricao: String;
    fonte_dados: String;
    tipo: String
}


interface JurisprudenciaSelecionada {
    id: number;
    identificador: String;
    informacoes: Informacoes;
    texto: Texto;
    ano: String;
    origem_dados: OrigemDados
}

type JurisprudenciaSelecionadaContextData = {
    handleGetJurisprudenciasSelecionadas: (expressao: String, filtros?: []) => Promise<void>;
    handleJurisprudenciaSelecionadaSelected: (id: number) => Promise<void>;
    handleGoToPage: (page: string) => void;
    handleCurrentPage: (page: number) => void;
    jurisprudenciasSelecionadas: JurisprudenciaSelecionada[];
    jurisprudenciaSelecionadaSelected: JurisprudenciaSelecionada;
    isLoadingJuris: boolean;
    count: number;
    next: string;
    previous: string;
    numberPage: number;
    currentPage: number;

}

type JurisprudenciaSelecionadaProviderProps = {

    //O tipo ReactNode pode receber qualquer coisa, componentes, funções etc
    children: ReactNode
}

export const JurisprudenciaSelecionadaContext = createContext({} as JurisprudenciaSelecionadaContextData);


export function JurisprudenciaSelecionadaProvider({ children }: JurisprudenciaSelecionadaProviderProps) {
    const [jurisprudenciasSelecionadas, setJurisprudenciasSelecionadas] = useState<JurisprudenciaSelecionada[]>([]);
    const [count, setCount] = useState<number>();
    const [numberPage, setNumberPage] = useState<number>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [next, setNext] = useState<string>();
    const [previous, setPrevious] = useState<string>();
    const [jurisprudenciaSelecionadaSelected, setJurisprudenciaSelecionadaSelected] = useState<JurisprudenciaSelecionada>();
    const [isLoadingJuris, setIsLoadingJuris] = useState(false);

    async function handleGetJurisprudenciasSelecionadas(expressao: String, filtros) {
        const searchParams = new URLSearchParams();
        let tribunais: [] = [];
        let filterTribunais: any

        if (expressao.length > 0) {
            searchParams.append('search', expressao.toString());
        }


        if (filtros.tribunais) {

            filtros.tribunais.map(tribunal => {
                tribunais.push(tribunal.id)
            }
            )
            tribunais.forEach((id, index) => {
                if (index === 0) {
                    filterTribunais = id
                } else {
                    filterTribunais = filterTribunais + '__' + id
                }
            })
            if (filterTribunais !== undefined) {

                searchParams.append('origem_dados__in', filterTribunais)
            }
        }
        if ((filtros.ano !== 'Invalid date' && filtros.ano !== 'Invalid date') && (filtros.ano['de'] !== undefined && filtros.ano['ate'] !== undefined)) {
            searchParams.append('ano__range', JSON.stringify(filtros.ano['de']) + '__' + JSON.stringify(filtros.ano['ate']))
        }


        try {
            setIsLoadingJuris(true)

            const { 'jurisconta.token': token } = parseCookies();
            const uri = `api/v1/juris_selecionadas-es/`

            if (token) {
                api.get(uri, {
                    params: searchParams,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {
                    let numPage = (response.data.count / 3)
                    setNumberPage(Math.ceil(numPage))
                    setCount(response.data.count);
                    setNext(response.data.next);
                    setPrevious(response.data.previous);
                    setJurisprudenciasSelecionadas(response.data.results)
                }).then(() => {
                    setIsLoadingJuris(false)
                    // setTimeout(() => {
                    // }, 2000)

                }).catch(error => {
                    console.log(error)
                    setIsLoadingJuris(false)
                })
            }

        } catch (err) {
            setIsLoadingJuris(false)
            console.log(err)
        }

    }

    async function handleJurisprudenciaSelecionadaSelected(id: number) {
        try {
            setIsLoadingJuris(true)

            const { 'jurisconta.token': token } = parseCookies();
            const uri = `api/v1/juris_selecionada/${id}/`

            if (token) {
                console.log(uri)
                api.get(uri, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {

                    setJurisprudenciaSelecionadaSelected(response.data)
                }).then(() => {
                    setTimeout(() => {
                        setIsLoadingJuris(false)
                    }, 2000)

                })
                    .catch(error => {
                        console.log(error)
                        setIsLoadingJuris(false)
                    })
            }

        } catch (err) {
            setIsLoadingJuris(false)
            console.log(err)
        }

    }

    function getInNextAno(value) {
        const regex = new RegExp('ano=(.*)page=');
        const result = regex.exec(value);
        if (result != null) {
            return result[1]
        }
        return
    };

    function getInNextSeach(value: string) {
        const regex = new RegExp('search=(.*)&tribunais');
        const result = regex.exec(value);
        return result[1];
    };

    function handleCurrentPage(page: number) {
        setCurrentPage(page)
    }

    function getInNextTribunais(value) {
        const regex = new RegExp('tribunais=(.*)');
        const result = regex.exec(value);
        return result[1];
    };

    async function handleGoToPage(page: string) {
        let paramAno: string;
        let paramSeach: string;
        let paramTribunais: string;
        if (next !== null && previous === null) {
            paramAno = getInNextAno(next)
            paramSeach = getInNextSeach(next)
            paramTribunais = getInNextTribunais(next)
        }
        if (next !== null && previous !== null) {

            if (currentPage < Number(page)) {
                paramAno = getInNextAno(next)
                paramSeach = getInNextSeach(next)
                paramTribunais = getInNextTribunais(next)

            }
            if (currentPage > Number(page)) {
                paramAno = getInNextAno(previous)
                paramSeach = getInNextSeach(previous)
                paramTribunais = getInNextTribunais(previous)

            }
        }
        if (next == null && previous !== null) {
            paramAno = getInNextAno(previous)
            paramSeach = getInNextSeach(previous)
            paramTribunais = getInNextTribunais(previous)

        }
        try {
            setIsLoadingJuris(true)

            const { 'jurisconta.token': token } = parseCookies();
            const uri = `api/v1/juris_selecionadas/?ano=${paramAno}page=${page}&search=${paramSeach}&tribunais=${paramTribunais}`

            if (token) {
                api.get(uri, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {
                    let numPage = (response.data.count / 3)
                    setNumberPage(Math.ceil(numPage))
                    setCount(response.data.count);
                    setNext(response.data.next);
                    setPrevious(response.data.previous);
                    setJurisprudenciasSelecionadas(response.data.results)
                }).then(() => {
                    setTimeout(() => {
                        setIsLoadingJuris(false)
                    }, 2000)

                })
                    .catch(error => {
                        console.log(error)
                        setIsLoadingJuris(false)
                    })
            }

        } catch (err) {
            setIsLoadingJuris(false)
            console.log(err)
        }

    }





    return (
        <JurisprudenciaSelecionadaContext.Provider value={
            {
                jurisprudenciasSelecionadas,
                jurisprudenciaSelecionadaSelected,
                isLoadingJuris,
                count,
                numberPage,
                next,
                previous,
                currentPage,
                handleCurrentPage,
                handleGoToPage,
                handleGetJurisprudenciasSelecionadas,
                handleJurisprudenciaSelecionadaSelected
            }}>
            {children}
        </JurisprudenciaSelecionadaContext.Provider>
    )
}