import axios, { AxiosError } from "axios";
import { parseCookies, setCookie } from "nookies";
import { AuthTokenError } from "./error/AuthTokenError";
import { desconectar } from "../contexts/auth/authContext";

let isRefreshingToken = false;
let failedRequestQueue = [];

export function setupAPIClient(ctx = undefined) {
    let cookies = parseCookies(ctx);
    const api = axios.create({
        baseURL: 'http://localhost:8000/',
        headers: {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${cookies['jurisconta.token']}`
        }
    });



    api.interceptors.response.use(response => {
        return response;
    }, (error: AxiosError) => {
        if (error.response.status === 401) {

            if (error.response.data?.code === "token_not_valid") {
                cookies = parseCookies(ctx);

                const { 'miner.refreshToken': refresh } = cookies;
                const originalConfig = error.config;

                if (!isRefreshingToken) {
                    isRefreshingToken = true;

                    api.post('/api/token/refresh/', { refresh: refresh }
                    ).then(response => {

                        const { access } = response.data;
                        setCookie(ctx, 'jurisconta.token', access, {
                            maxAge: 60 * 60 * 24 * 30, // 30 Dias
                            path: '/'
                        });
                        const { refresh } = response.data;
                        setCookie(ctx, 'miner.refreshToken', refresh, {
                            maxAge: 60 * 60 * 24 * 30, // 30 Dias
                            path: '/'
                        });

                        api.defaults.headers['Authorization'] = `Bearer ${access}`;

                        failedRequestQueue.forEach(request => request.onSuccess(access))

                        failedRequestQueue = [];
                    }
                    ).catch(err => {
                        failedRequestQueue.forEach(request => request.onFailure(err))

                        failedRequestQueue = [];

                        if (process?.browser) {
                            // desconectar()
                        }

                    }).finally(() => {
                        isRefreshingToken = false;
                    })
                }
                return new Promise((resolve, reject) => {
                    failedRequestQueue.push({
                        onSuccess: (token: string) => {
                            originalConfig.headers['Authorization'] = `Bearer ${token}`;
                            resolve(api(originalConfig));

                        },
                        onFailure: (error: AxiosError) => {
                            reject(error);
                        }
                    })
                })
            } else {
                if (process.browser) {
                    desconectar()
                } else {
                    return Promise.reject(new AuthTokenError())
                }
            }
        }

        return Promise.reject(error);

    });

    return api;

}