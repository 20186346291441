import { useState, createContext, ReactNode } from "react";
import { parseCookies } from "nookies";
import { setTimeout } from "timers";
import { api } from "../../services/apiClient";

interface Documento {
    id: number;
    criado_em: String;
    atualizado_em: String;
    cd_documento: String;
    binario: String;
}

type DocumentoContextData = {

    handleGetDocumento: (id: number) => Promise<void>
    documento: Documento;
    isLoading: boolean;
}

type DocumentoProviderProps = {
    children: ReactNode
}

export const DocumentoContext = createContext({} as DocumentoContextData);


export function DocumentoProvider({ children }: DocumentoProviderProps) {
    const [documento, setDocumento] = useState<Documento>();
    const [isLoading, setIsLoading] = useState(false)

    async function handleGetDocumento(id: number) {
        setIsLoading(true)
        try {
            const { 'jurisconta.token': token } = parseCookies();
            if (token) {
                api.get(`api/v2/documento/${id}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                ).then(response => {
                    setDocumento(response.data)
                })
                    .then(() => {
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 2000)

                    })
                    .catch(error => {
                        console.log(error)
                        setIsLoading(false)
                    })
            }

        } catch (err) {
            console.log(err)
        }

    }

    return (
        <DocumentoContext.Provider value={{ handleGetDocumento, documento, isLoading }}>
            {children}
        </DocumentoContext.Provider>
    )
}